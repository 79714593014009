import React, { Component } from 'react';
import { withMsal } from "@azure/msal-react";
import { apiRequest } from "../authConfig";
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "reactstrap";

class PersonAddComponent extends Component {
    static displayName = PersonAddComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            title: '',
            email: '',
            modal: false,
            accessToken: ''
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        const msalInstance = this.props.msalContext.instance;
        const accounts = this.props.msalContext.accounts;
        const request = {
            ...apiRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request 
        msalInstance.acquireTokenSilent(request).then((response) => {
            this.setState({
                accessToken: response.accessToken
            });
        }).catch((e) => {
            msalInstance.acquireTokenPopup(request).then((response) => {
                this.setState({
                    accessToken: response.accessToken
                });
            });
        });
    }

    toggleModal() {
        this.setState({ modal: !this.state.modal })
    }

    onChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    async onSave() {
        const headers = new Headers();
        const bearer = `Bearer ${this.state.accessToken}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", "application/json");

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                title: this.state.title,
                email: this.state.email
            })
        };
        await fetch(`api/people`, options);
        this.setState({ modal: !this.state.modal })
    }

    render() {
        const { firstName, lastName, title, email, modal } = this.state;
        return (
            <>
                <Button onClick={e => this.toggleModal()}>Add</Button>
                <Modal isOpen={modal}>
                    <ModalHeader>New Person</ModalHeader>
                    <ModalBody>
                        <Form inline>
                            <FormGroup floating>
                                <Input
                                    id="title"
                                    name="title"
                                    type="select"
                                    value={title}
                                    onChange={this.onChange}>
                                    <option></option>
                                    <option>Mr.</option>
                                    <option>Mrs.</option>
                                    <option>Ms.</option>
                                </Input>
                                <Label for="title">Title</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    id="firstName"
                                    name="firstName"
                                    placeholder="John"
                                    value={firstName}
                                    onChange={this.onChange}
                                />
                                <Label for="firstName">First</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Smith"
                                    value={lastName}
                                    onChange={this.onChange}
                                />
                                <Label for="lastName">Last</Label>
                            </FormGroup>
                            <FormGroup floating>
                                <Input
                                    id="email"
                                    name="email"
                                    placeholder="john@adventure-works.com"
                                    type="email"
                                    value={email}
                                    onChange={this.onChange}
                                />
                                <Label for="email">Email</Label>
                            </FormGroup>

                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={e => this.toggleModal()}>Cancel</Button>
                        <Button color="primary" onClick={e => this.onSave()}>Save</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

export const PersonAdd = withMsal(PersonAddComponent);