import React, { useState, useEffect, useRef } from 'react';
import * as signalR from "@microsoft/signalr";
import { Alert, ListGroup, ListGroupItem } from "reactstrap";

export const Events = () => {
    const [hubConnection, setHubConnection] = useState(null);
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(true);
    const latestLog = useRef(null);

    latestLog.current = list;

    useEffect(() => {
        const hubConnection = new signalR.HubConnectionBuilder()
            .withUrl("/api/logs", {
                skipNegotiation: true,
                transport: signalR.HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();
        setHubConnection(hubConnection);
    }, []);

    useEffect(() => {
        if (hubConnection) {
            hubConnection.start()
            .then(result => {
                hubConnection.on("sendToReact", message => {
                    const updatedLog = [...latestLog.current];
                    updatedLog.push(message);
                    setLoading(false);
                    setList(updatedLog);
                });
            });
        }
    }, [hubConnection]);

    const renderEventsTable = () => {
        return (
            <ListGroup flush>
                {list.map((message, index) => <ListGroupItem key={`message${index}`}>{message}</ListGroupItem>)}
            </ListGroup>
        );
    }

    let contents = loading
        ? <Alert color="primary">Nothing to show.</Alert>
        : renderEventsTable();

    return (
        <div>
            <h1>Events</h1>
            <p className="lead">
                This component leverages RabbitMq for publishing and consuming
                events. When an authenticated user views <a href="/people">people</a>,
                the API will publish an event on the RabbitMq exchange. A background
                worker on the API, which listens for events, receives the message
                and then publishes the message to all browsers connected via
                web sockets.
            </p>
            {contents}
        </div>
    );
}
