import { People } from "./components/People";
import { Home } from "./components/Home";
import { Events } from "./components/Events";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/people',
    element: <People />
    },
    {
        path: '/events',
        element: <Events />
    }
];

export default AppRoutes;
