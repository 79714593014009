export const msalConfig = {
    auth: {
        clientId: "c1d65ce4-ed7d-42d4-bd20-13d07831302a",
        authority: "https://login.microsoftonline.com/da7b84dc-6748-4ba5-9b02-1f3a0bdc2b50", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        // redirectUri: "https://localhost:44488", // will redirect by default to the page it's running on
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ["User.Read"]
};

export const apiRequest = {
    scopes: ["api://c1d65ce4-ed7d-42d4-bd20-13d07831302a/access_as_user"]
}; 