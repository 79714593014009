import React, { Component } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, withMsal } from "@azure/msal-react";
import { apiRequest } from "../authConfig";
import ReactPaginate from "react-paginate";
import { PersonAdd } from "./PersonAdd";
import { Spinner, Container, Row, Col } from "reactstrap";

class PeopleComponent extends Component {
    static displayName = PeopleComponent.name;

    constructor(props) {
        super(props);
        this.state = {
            people: [],
            loading: true,
            duration: '',
            page: 0,
            totalPages: 0,
            total: 0,
            accessToken: ''
        };
    }

    componentDidMount() {
        const msalInstance = this.props.msalContext.instance;
        const accounts = this.props.msalContext.accounts;
        const request = {
            ...apiRequest,
            account: accounts[0]
        };
        // Silently acquires an access token which is then attached to a request 
        msalInstance.acquireTokenSilent(request).then((response) => {
            this.populatePeopleData(response.accessToken, this.state.page);
        }).catch((e) => {
            msalInstance.acquireTokenPopup(request).then((response) => {
                this.populatePeopleData(response.accessToken, this.state.page);
            });
        });
    }

    onNext(page) {
        this.setState({ loading: true });
        this.populatePeopleData(this.state.accessToken, page)
    }

    renderPeopleTable(people, duration, currentPage, total, totalPages) {
        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <td colSpan="4">
                            <Container>
                                <Row>
                                    <Col>
                                        <PersonAdd />
                                    </Col>
                                    <Col xs="auto">
                                        <ReactPaginate
                                            onPageChange={e => this.onNext(e.selected)}
                                            previousLabel="Previous"
                                            nextLabel="Next"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={totalPages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={4}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={currentPage}
                                            renderOnZeroPageCount={null} />
                                    </Col>
                                </Row>
                            </Container>
                        </td>
                    </tr>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Title</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    {people.map(person =>
                        <tr key={person.personId}>
                            <td>{person.firstName}</td>
                            <td>{person.lastName}</td>
                            <td>{person.title}</td>
                            <td>{person.email}</td>
                        </tr>
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="4">
                            <Container>
                                <Row>
                                    <Col>
                                        Query duration: {duration}
                                    </Col>
                                    <Col xs="auto">
                                        <ReactPaginate
                                            onPageChange={e => this.onNext(e.selected)}
                                            previousLabel="Previous"
                                            nextLabel="Next"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakLabel="..."
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            pageCount={totalPages}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={4}
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            forcePage={currentPage}
                                            renderOnZeroPageCount={null} />
                                    </Col>
                                </Row>
                            </Container>
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    }

    render() {
        const { page, people, loading, duration, total, totalPages } = this.state;
        let contents = loading
            ? <div><Spinner size="sm" color="primary">Loading...</Spinner> <span>Loading...</span></div>
            : this.renderPeopleTable(people, duration, page, total, totalPages);

        return (
            <div>
                <h1 id="tabelLabel" >People</h1>
                <p className="lead">This component demonstrates fetching data from the server.</p>
                <AuthenticatedTemplate>
                    {contents}
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <p>You must sign-in to view data</p>
                </UnauthenticatedTemplate>
            </div>
        );
    }

    async populatePeopleData(accessToken, page) {
        const headers = new Headers();
        const bearer = `Bearer ${accessToken}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };
        const response = await fetch(`api/people?page=${page}`, options);
        const data = await response.json();
        this.setState({
            people: data.people,
            loading: false,
            page: page,
            duration: data.duration,
            total: data.total,
            totalPages: data.totalPages,
            accessToken: accessToken
        });
    }
}

export const People = withMsal(PeopleComponent);