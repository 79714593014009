import React, { Component } from 'react';
import onpremImg from './hello-world-architecture.png'
import { Spinner } from "reactstrap";

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            appName: 'Loading...',
            loading: true,
        };
    }

    componentDidMount() {
        this.populateAppData();
    }

    renderAppData(appName) {
        return (
            <div>
                <h1>{appName}!</h1>
                <h4>version 0.2</h4>
            </div>
        );
    }
    render() {
        const { appName, loading } = this.state;
        let contents = loading
            ? <div><Spinner size="sm" color="primary">Loading...</Spinner> <span>Loading...</span></div>
            : this.renderAppData(appName);
        return (
            <div>
                {contents}
                <p>Welcome to your new single-page application, built with:</p>

                <h3>Current Architecture</h3>
                <ul>
                    <li><a href='https://get.asp.net/'>ASP.NET Core</a> and <a href='https://msdn.microsoft.com/en-us/library/67ef8sbd.aspx'>C#</a> for cross-platform server-side code</li>
                    <li><a href='https://facebook.github.io/react/'>React</a> for client-side code</li>
                    <li><a href='http://getbootstrap.com/'>Bootstrap</a> for layout and styling</li>
                </ul>
                <p>To help you get started, we have also set up:</p>
                <ul>
                    <li><strong>Client-side navigation</strong>. For example, click <em>Counter</em> then <em>Back</em> to return here.</li>
                    <li><strong>Development server integration</strong>. In development mode, the development server from <code>create-react-app</code> runs in the background automatically, so your client-side resources are dynamically built on demand and the page refreshes when you modify any file.</li>
                    <li><strong>Efficient production builds</strong>. In production mode, development-time features are disabled, and your <code>dotnet publish</code> configuration produces minified, efficiently bundled JavaScript files.</li>
                </ul>
                <p>The <code>ClientApp</code> subdirectory is a standard React application based on the <code>create-react-app</code> template. If you open a command prompt in that directory, you can run <code>npm</code> commands such as <code>npm test</code> or <code>npm install</code>.</p>
            </div>
        );
    }
    async populateAppData() {

        const response = await fetch(`api/home`);
        const data = await response.json();
        this.setState({
            appName: data.appName,
            loading: false,
        });
    }
}
